body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.login-container .login-logo {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 8px;
  height: 180px;
  margin: 1rem;
  width: 180px;
}

.login-title{
  font-weight: bold !important;
}
.login-subtitle{
  font-size: 18px !important;
  text-align: center;
}
.login-ttitle{
  font-size: 16px !important;
  text-align: center;
}

.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}


.firma{
  display: flex;
    flex-direction: column;
    border: 1px solid darkgrey;
}

.errorText{
  color: #DB0000;
  font-size: small;
}